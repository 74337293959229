import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import * as arrayHelpers from "../../helpers/arrayHelpers"
import * as userHelpers from "../../helpers/userHelpers"
import {
  formattedSelectedService,
  getServiceNameForMap,
  selectOptions
} from "../../helpers/serviceHelpers"
import * as shopHelpers from "../../helpers/shopHelpers"
import * as vehicleHelpers from "../../helpers/vehicleHelpers"
import {
  isDTCAFleet,
  isFleetAdvise,
  isFleetAmerica,
  isFleetcor
} from "../../helpers/affiliationHelpers"
import { sortByAttr } from "../../helpers/arrayHelpers"
import Button from "../../elements/Button"
import MultiSelect from "../../elements/MultiSelect"
import Select from "../../elements/Select"
import SelectedServices from "./SelectedServices"
import ServicesTotal from "./ServicesTotal"
import TextField from "../../elements/TextField"
import { Segment, Form, Header, Grid, Divider } from "semantic-ui-react"
import ShopList from "../shops/ShopList"
import { uniqBy } from "lodash"
import { getStyles } from "../shared/StylesheetInjector"
import { withTranslation } from "react-i18next"
import {
  MD_HD_VEHICLE_TYPE_ID,
  FLEET_AMERICA,
  FLEET_MAINTENANCE_HUB,
  FLEET_MAINTENANCE_HUB_CANADA,
  ZUBIE,
  NTWTBC,
  DISCOUNT_TIRE
} from "../../constants/application"
import { getSubdomain } from "../shared/StylesheetInjector"
import { trackEvent } from "../../utilities/segmentAnalytics"
import { EXCLUDED_ROLES, isDriver } from "../../helpers/userRoleHelpers"
import { serviceSelectedEvents, shopSelectedEvents } from "../../helpers/googleAnalyticsHelpers"
import { setupSplitFlags } from "../../components/config/SplitClient"
import { isEmpty } from "lodash"

const styles = getStyles()

class ServiceRequestForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    services: PropTypes.array.isRequired,
    favoriteShops: PropTypes.array.isRequired,
    vehicles: PropTypes.array.isRequired,
    isSubmitting: PropTypes.bool,
    gotoShopSearch: PropTypes.func.isRequired
  }

  static defaultProps = {
    isSubmitting: false
  }

  state = {
    order_services: [],
    service_options: [],
    favorite_shops: [],
    originator_id: this.props.currentUser.id,
    originator: this.props.currentUser,
    service_tire_promotion_option: false,
    ...this.props.previousFormData
  }

  async componentDidMount() {
    await setupSplitFlags.bind(this)({ sub_domain: getSubdomain() })
    this.props.dispatch({
      type: "SHOPS_FILTER_SUCCEEDED",
      payload: { shops: [], pageCount: 1 }
    })
    this.updatePrices()
    this.shopSelectOptions()
    this.availableServices()
  }

  componentDidUpdate(prevProps) {
    if (this.props.services !== prevProps.services) {
      this.availableServices()
    }
  }

  update_options() {
    const { vehicle, service_options, favorite_shops, order_services, shop_id } = this.state
    if (vehicle && vehicle.vehicle_type_id === MD_HD_VEHICLE_TYPE_ID) {
      var serviceOptions = service_options.filter(
        (service) => service.support_medium_and_heavy_duty_vehicles === true
      )
      var favoriteShops = favorite_shops.filter(
        (shop) => shop.support_medium_and_heavy_duty_vehicles === true
      )
      var orderServices = order_services.filter(
        (service) => service.support_medium_and_heavy_duty_vehicles === true
      )
      if (favoriteShops.filter((e) => e.id === shop_id).length <= 0) {
        this.setState({ shop_id: null })
      }
      this.setState({
        service_options: serviceOptions,
        order_services: orderServices,
        favorite_shops: favoriteShops
      })
    } else {
      this.availableServices()
      this.shopSelectOptions()
    }
  }

  allowSubmit(skip_shop = false) {
    const { isSubmitting } = this.props
    const { shop_id, vehicle_id, order_services } = this.state

    return (
      !isSubmitting &&
      (skip_shop || shop_id) &&
      vehicle_id &&
      order_services &&
      order_services.length > 0 &&
      this.completedServiceSelections()
    )
  }

  availableServices() {
    const { services } = this.props
    const { order_services, vehicle } = this.state
    var selected_service_ids, serviceOptions
    if (vehicle && vehicle.vehicle_type_id === MD_HD_VEHICLE_TYPE_ID) {
      selected_service_ids = order_services.map((selected_service) => selected_service.id)
      serviceOptions = services.filter(
        (service) =>
          !selected_service_ids.includes(service.id) &&
          service.support_medium_and_heavy_duty_vehicles === true
      )
    } else {
      selected_service_ids = order_services.map((selected_service) => selected_service.id)
      serviceOptions = services.filter((service) => !selected_service_ids.includes(service.id))
    }
    this.setState({ service_options: serviceOptions })
  }

  completedServiceSelections() {
    const { order_services } = this.state

    let isComplete = true

    // NOTE: Enforces at least 1 service selected
    if (order_services.length === 0) {
      isComplete = false
    }

    // NOTE: Enforces that all services that require a position selection have one.
    order_services.forEach((service) => {
      if (
        !(service.hasOwnProperty("positionOptions") && service.positionOptions.length > 0) &&
        service.hasOwnProperty("position") &&
        service.position === ""
      ) {
        isComplete = false
      }
    })

    return isComplete
  }

  formattedFormData() {
    let formData = { ...this.state }
    formData["shop_id"] = parseInt(formData.shop_id)
    formData["vehicle_id"] = parseInt(formData.vehicle_id)
    return formData
  }

  filterShopsParams() {
    let { vehicle_id, order_services } = this.state
    let vehicle_type_id
    if (this.state.vehicle) vehicle_type_id = this.state.vehicle.vehicle_type_id
    return {
      vehicle_id,
      vehicle_type_id,
      services_requested: order_services.map((service) => {
        return {
          position: service.position,
          service_definition_id: service.id
        }
      })
    }
  }

  onChange(field, value) {
    this.setState({ [field]: value })
    if (field === "originator_id") {
      this.setState({ originator: this.props.basicUsers.find((user) => user.id === value) })
    }
  }

  onServiceOptionChange(serviceId, optionType, value) {
    const { order_services } = this.state
    const updatedSelectedServices = order_services.map((selected_service) => {
      return parseInt(selected_service.id) === parseInt(serviceId)
        ? { ...selected_service, [optionType]: value }
        : selected_service
    })
    this.setState({ order_services: updatedSelectedServices })
  }

  onServiceRemoval(value) {
    const { order_services } = this.state
    const { location } = this.props

    const updatedSelectedServices = arrayHelpers.removeObjectById(order_services, value)
    this.setState(
      () => {
        return {
          order_services: updatedSelectedServices
        }
      },
      () => {
        this.availableServices()
        if (location.length != 0) {
          this.searchByLocation(location)
        }
      }
    )
  }

  searchByLocation = (locationData) => {
    const { dispatch } = this.props

    dispatch({
      type: "SHOPS_FILTER_SAGA",
      payload: {
        asSubForm: true,
        filterShopsParams: this.filterShopsParams(),
        filters: {
          searchLocation: {
            latitude: locationData.latitude,
            longitude: locationData.longitude
          }
        },
        pageCount: 1
      }
    })
  }

  onServiceSelection(selectedServices) {
    const { services, vehicles, location } = this.props
    const { order_services, vehicle_id } = this.state

    const vehicle = arrayHelpers.findObjectById(vehicles, vehicle_id)
    const targetOrderService = arrayHelpers.findObjectById(
      services,
      (selectedServices[0] || {}).value
    )
    if (!targetOrderService) return
    const selectedOrderService = formattedSelectedService({ service: targetOrderService, vehicle })
    const updatedSelectedServices = arrayHelpers.toggleElement(order_services, selectedOrderService)

    this.setState(
      {
        order_services: updatedSelectedServices
      },
      () => {
        this.availableServices()
        this.updatePrices()
        if (location.length != 0) {
          this.searchByLocation(location)
        }
      }
    )

    let formData = { ...this.state }
    let vehicleData = formData && formData.vehicle
    let eventData = {}
    eventData.servicesSelected = updatedSelectedServices.map(getServiceNameForMap)
    if (vehicleData) {
      eventData.vehicleYear = vehicleData.year
      eventData.vehicleMake = vehicleData.make
      eventData.vehicleModel = vehicleData.model
      eventData.vin = vehicleData.vin
    }
    serviceSelectedEvents({
      vehicle: vehicleData,
      serviceDefinition: eventData.servicesSelected
    })
    trackEvent("click-add-service", eventData)
  }

  // NOTE: The resetting of order_services on shop change has been disabled. It was intended for when each shop would have its own services / prices.
  onShopChange(value) {
    this.setState(
      {
        // order_services: [],
        shop_id: value,
        shop: this.props.favoriteShops.find((s) => s.id == parseInt(value))
      },
      () => {
        this.updatePrices()
      }
    )
    let formData = { ...this.state }
    let shop = this.props.favoriteShops.find((s) => s.id == parseInt(value))
    let eventData = {}

    if (shop) {
      eventData.shopName = shop.name
      eventData.shopAddress = `${shop.address_line1} ${shop.city}, ${shop.state} ${shop.zip}`
    }

    if (formData) {
      if (formData.order_services && formData.order_services.length > 0) {
        eventData.servicesSelected = formData.order_services.map(getServiceNameForMap)
      }

      if (formData.vehicle) {
        eventData.vehicleYear = formData.vehicle.year
        eventData.vehicleMake = formData.vehicle.make
        eventData.vehicleModel = formData.vehicle.model
        eventData.vin = formData.vehicle.vin
      }
    }

    if (shop && shop.id) {
      shopSelectedEvents({
        shop: shop,
        vehicle: formData.vehicle,
        order_services: formData.order_services
      })
    }
    trackEvent("click-service-center", eventData)
  }

  handleSelectShop = (shop) => {
    const { onSubmit } = this.props
    this.setState(
      {
        shop_id: shop.id,
        shop
      },
      async () => {
        await this.updatePrices()
        onSubmit(this.formattedFormData())
      }
    )
    let formData = { ...this.state }
    let eventData = {}

    if (shop) {
      eventData.shopName = shop.name
      eventData.shopAddress = `${shop.address_line1} ${shop.city}, ${shop.state} ${shop.zip}`
    }

    if (formData) {
      if (formData.order_services && formData.order_services.length > 0) {
        eventData.servicesSelected = formData.order_services.map(getServiceNameForMap)
      }

      if (formData.vehicle) {
        eventData.vehicleYear = formData.vehicle.year
        eventData.vehicleMake = formData.vehicle.make
        eventData.vehicleModel = formData.vehicle.model
        eventData.vin = formData.vehicle.vin
      }
    }

    shopSelectedEvents({
      shop: shop,
      vehicle: formData.vehicle,
      order_services: formData.order_services
    })
    trackEvent("click-service-center", eventData)
  }

  shopSelectOptions() {
    const { vehicle } = this.state
    let additionalShops = this.props.shops
    if (this.state.shop) additionalShops = additionalShops.concat([this.state.shop])

    const preSelectedShop = additionalShops.filter((shop) => {
      return shop.id === this.state.shop_id
    })

    var favoriteShops = uniqBy(this.props.favoriteShops.concat(preSelectedShop), "id")

    if (vehicle && vehicle.vehicle_type_id === MD_HD_VEHICLE_TYPE_ID) {
      favoriteShops = favoriteShops.filter(
        (shop) => shop.support_medium_and_heavy_duty_vehicles === true
      )
    }

    this.setState({ favorite_shops: favoriteShops })
  }

  onVehicleChange(value) {
    const { location } = this.props
    var serviceOptions, orderServices
    if (value) {
      this.setState(
        {
          vehicle_id: value,
          vehicle: this.props.vehicles.find((s) => s.id == parseInt(value))
        },
        () => {
          this.updatePrices()
          this.update_options()
          if (location.length != 0) {
            this.searchByLocation(location)
          }
        }
      )
    }
  }

  async updatePrices() {
    const { vehicle_id, shop_id, order_services } = this.state
    const service_ids = order_services.map((os) => os.id)

    if (vehicle_id && shop_id && service_ids[0]) {
      this.props.dispatch({
        type: "SERVICE_REQUEST_GET_PRICES_SAGA",
        payload: {
          vehicle_id: vehicle_id,
          shop_id: shop_id,
          service_ids: service_ids
        }
      })
    }
  }

  onRequestFailure(data) {
    this.setState({
      alertMessage: data.alertMessage,
      alertType: data.alertType
    })
  }

  onRequestSuccess(data) {
    this.shopSelectOptions()
    // NOTE: Intentionally left blank.
  }

  afterRequest(data) {
    if (data.status === "success") {
      this.onRequestSuccess(data)
    } else {
      this.onRequestFailure(data)
    }
  }

  onFavoriteSubmit = (formData) => {
    this.props.dispatch({
      type: "SHOP_FAVORITE_SAGA",
      payload: { formData: formData },
      callback: this.afterRequest.bind(this)
    })
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    if (!window.location.href.includes("service_request/new")) {
      dispatch({
        type: "RESET_SHOPS_FILTER_SAGA"
      })
      dispatch({
        type: "RESET_SHOP_LOCATION"
      })
    }
  }

  customFilterOption = (option, inputValue) => {
    if (this.state.showTirePromotionBannerFlag == getSubdomain()) {
      if (inputValue.includes("tire") && !this.state.service_tire_promotion_option) {
        this.setState({ service_tire_promotion_option: true })
      } else if (!inputValue.includes("tire") && this.state.service_tire_promotion_option) {
        this.setState({ service_tire_promotion_option: false })
      }
    }

    if (typeof option.label == "string")
      return option.label.toLowerCase().includes(inputValue.toLowerCase())
    else return option
  }

  render() {
    const {
      onSubmit,
      vehicles,
      gotoShopSearch,
      t,
      totalPages,
      pageCount,
      basicUsers,
      currentUser
    } = this.props
    const isEnabled = this.allowSubmit()
    const {
      order_services,
      shop_id,
      shop,
      vehicle_id,
      vehicle,
      service_options,
      originator_id,
      originator,
      showTirePromotionBannerFlag,
      showTirePromotionBannerConfig
    } = this.state
    const domain = getSubdomain()
    var md_hd_label_color
    if (isFleetcor()) md_hd_label_color = "#ee3a43"
    else if (domain === FLEET_AMERICA) md_hd_label_color = "#f8991d"
    else if (domain === FLEET_MAINTENANCE_HUB || domain === FLEET_MAINTENANCE_HUB_CANADA)
      md_hd_label_color = "#003c88"
    else if (domain === ZUBIE) md_hd_label_color = "#00c1de"
    else if (domain === NTWTBC) md_hd_label_color = "#005ca9"
    else if (domain === DISCOUNT_TIRE) md_hd_label_color = "#e6121a"
    else md_hd_label_color = "#292f36"
    const isUserDriver = isDriver(currentUser)
    return (
      <Form>
        <Segment padded raised>
          <Grid stackable columns={2}>
            <Grid.Column width={7}>
              <Header as="h3">{t("appointmentInformationLabel")}</Header>
              <Divider />

              <Select
                onChange={(event) => event.target.value && this.onVehicleChange(event.target.value)}
                options={vehicleHelpers.selectOptions(vehicles, { displayOtherID: true })}
                placeholder={t("maintenanceHistory:selectVehicleLabel")}
                defaultValue={vehicle_id}
                value={vehicle_id}
              />

              <Divider hidden />

              {isFleetAmerica() && (
                <span className="link" onClick={() => gotoShopSearch(this.formattedFormData())}>
                  {t("searchShopLabel")}!
                </span>
              )}
              {!isFleetAmerica() && (
                <span
                  className={`primary-button-color ${
                    isFleetAdvise() ? "favorite-shop-text-color" : ""
                  }`}
                >
                  {t("selectYourFavouriteShopLabel")}
                </span>
              )}

              <Select
                onChange={(event) => this.onShopChange(event.target.value)}
                options={shopHelpers.selectOptions(this.state.favorite_shops, {
                  includeBlank: true,
                  vehicle: vehicle
                })}
                placeholder={
                  this.state.favorite_shops.length === 0
                    ? t("noFavouriteShopsLabel")
                    : t("selectFavouriteShopLabel")
                }
                defaultValue={shop_id}
                value={shop_id}
              />

              <Divider hidden />
              <div style={{ display: "flex" }}>
                <Form.Field style={{ width: isUserDriver ? "100%" : "50%" }}>
                  <TextField
                    onChange={(event) => this.onChange("po_number", event.target.value)}
                    placeholder={t("maintenanceHistory:poNumberLabel")}
                    value={this.state.po_number}
                  />
                </Form.Field>
                {!isUserDriver && (
                  <Select
                    onChange={(event) => this.onChange("originator_id", event.target.value)}
                    options={userHelpers.selectOptions(basicUsers, { semantic: true })}
                    placeholder={t("reservationForLabel")}
                    value={originator_id}
                  />
                )}
              </div>
              <Divider hidden />
              <TextField
                onChange={(event) => this.onChange("customer_comments", event.target.value)}
                placeholder={t("maintenanceHistory:customerCommentsLabel")}
                value={this.state.customer_comments}
              />
            </Grid.Column>

            <Grid.Column width={7}>
              <Header as="h3">{t("maintenanceHistory:servicesLabel")} </Header>
              <Divider />

              <MultiSelect
                disabled={false}
                onChange={(selection) => this.onServiceSelection(selection)}
                options={selectOptions(sortByAttr(service_options, "name"), {
                  includeBlank: true,
                  vehicle: vehicle,
                  showTirePromotionPlaceholder: this.state.service_tire_promotion_option,
                  showTirePromotionBannerConfig:
                    !isEmpty(showTirePromotionBannerConfig) && showTirePromotionBannerConfig
                })}
                placeholder={t("addServiceLabel")}
                value={[]}
                md_hd_label_color={md_hd_label_color}
                customFilterOption={this.customFilterOption}
              />

              <Divider hidden />

              <SelectedServices
                onRemoval={this.onServiceRemoval.bind(this)}
                onServiceOptionChange={this.onServiceOptionChange.bind(this)}
                services={this.state.order_services}
                vehicle_type_id={vehicle && vehicle.vehicle_type_id}
              />

              {/* NOTE: Service prices have been disabled */}
              {/* NOTE: If re-enabled, ServicesTotal component will need to be updated for new order_services structure */}
              {false && <ServicesTotal services={order_services} />}
            </Grid.Column>
          </Grid>

          <Grid centered>
            <Grid.Row>
              <div className={"submit-container"}>
                <Button
                  disabled={!isEnabled}
                  label={t("common:scheduleAppointmentLabel")}
                  onClick={() => onSubmit(this.formattedFormData())}
                />
              </div>
            </Grid.Row>
          </Grid>
        </Segment>

        {!isFleetAmerica() && (
          <Segment padded raised>
            <Header as="h3">{t("common:seeAllLocationsLabel")}</Header>
            <Divider />
            <ShopList
              searchMode={true}
              filterShopsParams={this.filterShopsParams()}
              previousFormData={this.formattedFormData()}
              shops={this.props.shops}
              isLoading={this.props.isInnerLoading}
              isLoadingError={this.props.isLoadingError}
              onFavoriteSubmit={this.onFavoriteSubmit}
              handleSelectShop={this.handleSelectShop}
              disableSubmit={!this.allowSubmit(true)}
              asSubForm={true}
              totalPages={totalPages}
              pageCount={pageCount}
              vehicle_type_id={vehicle && vehicle.vehicle_type_id}
            />
          </Segment>
        )}
      </Form>
    )
  }
} // class ServiceRequestForm

const mapStateToProps = (state) => {
  const currentUser = state.application.userSessionData || state.users.currentUser
  return {
    users: state.users.users,
    currentUser: currentUser,
    services: state.services.services,
    vehicles: state.vehicles.vehicles,
    shops: state.shops.shops,
    isInnerLoading: state.application.isInnerLoading,
    location: state.shops.location,
    totalPages: state.shops.totalPages,
    pageCount: state.shops.pageCount,
    basicUsers: state.users.basicUsers.filter(
      (user) =>
        user.id === currentUser.id ||
        !EXCLUDED_ROLES.some((excludedRole) => user.roles.includes(excludedRole))
    )
  }
}

export default connect(mapStateToProps)(withTranslation("scheduleRequest")(ServiceRequestForm))
