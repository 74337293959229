import React, { Component } from "react"
import { connect } from "react-redux"
import "react-datepicker/dist/react-datepicker.css"
import { DISPLAY_STATUSES } from "../../constants/statuses"
import { constants } from "caradvise_shared_components"
import { Link } from "react-router-dom"
import { navigationPaths } from "../../constants/paths"
import LoadingThrobber from "../../components/shared/LoadingThrobber"
import {
  activeOrderGridColumnDefs,
  activeOrderGridRowData,
  ACTIVE,
  SCHEDULE_BUTTON_FIELD,
  MANUAL_MAINTENANCE_TYPE
} from "../../constants/orders"
import * as agGridHelpers from "../../helpers/agGridHelpers"
import { Segment, Modal, Menu, Button, Grid } from "semantic-ui-react"
import { AgGridReact } from "ag-grid-react"
import ScheduleServiceButton from "../../components/shared/ScheduleServiceButton"
import FMCOrderHeader from "../../components/shared/FMCOrderHeader"
import * as maintenanceHelpers from "../../helpers/maintenanceHelpers"
import { withTranslation } from "react-i18next"
import frGrid from "../../locales/fr-ca/grid.json"
import enGrid from "../../locales/en/grid.json"
import { FR_LOCALE } from "../../constants/users"
import CustomNoRowsOverlay from "../../components/shared/CustomNoRowsOverlay"
import ApplicationLayout from "../../layouts/ApplicationLayout"
import { isFleetAdvise } from "../../helpers/affiliationHelpers"
import FMCTransactionsShimmer from "./FMCTransactionsShimmer"
import { logFirebaseAnalyticsScreenName } from "../../helpers/googleAnalyticsHelpers"
import { getRouteNameFromUrl } from "../../helpers/segmentHelpers"

const { AWAITING_APPROVAL } = constants.orders
const DUE_FILTER_ALL = "all"
const DUE_FILTER_DUE_SOON = "dueSoon"
const DUE_FILTER_PAST_DUE = "pastDue"

class FMCActiveOrders extends Component {
  constructor(props) {
    super(props)
  }

  state = {
    dueFilter: DUE_FILTER_ALL,
    activePage: ACTIVE,
    orderFilter: null,
    showGrid: false,
    filteredItems: [],
    pages: [
      {
        name: ACTIVE,
        label: "activeOrdersLabel",
        rowData: activeOrderGridRowData,
        columnDefs: activeOrderGridColumnDefs
      }
    ]
  }

  servicesCellRenderer = (cellParams) => {
    const services = cellParams.value
    return (
      <Modal size="mini" trigger={<span>{services}</span>}>
        <Modal.Content>{services}</Modal.Content>
      </Modal>
    )
  }

  onGridReady = (params) => {
    this.api = params.api
    this.columnApi = params.columnApi
    this.setFilteredItems()
  }

  schedulerCellRenderer = (cellParams) => {
    if (cellParams && cellParams.value) {
      const { maintenance, vehicle } = cellParams.value

      return <ScheduleServiceButton secondary vehicle={vehicle} maintenance={maintenance} />
    }
  }

  statusCellRenderer = (cellParams, t) => {
    if (cellParams && cellParams.value) {
      const { status, id } = cellParams.value
      if (status !== AWAITING_APPROVAL) {
        return t(DISPLAY_STATUSES[status])
      } else {
        return (
          <Button onClick={() => this.onReviewClick(id, cellParams.data.fleet_id)}>
            {t("reviewLabel")}
          </Button>
        )
      }
    }
  }

  onReviewClick = async (id, fleetId) => {
    await this.props.dispatch({
      type: "FLEET_IMPERSONATION_SAGA",
      payload: { fleetId: fleetId }
    })
    await this.props.history.push(navigationPaths.approvalsShow(id))
  }

  viewOrder = (cell) => {
    if (cell.colDef.field !== SCHEDULE_BUTTON_FIELD) {
      // cell.data.status being null or not differentiates ShopOrder vs Vehicle
      if (cell.data.status == null) {
        const path = navigationPaths.vehicleShow
        this.props.history.push(path(cell.data.id))
      } else if (cell.data.maintenance_type === MANUAL_MAINTENANCE_TYPE) {
        const path = navigationPaths.vehicleShow
        this.props.history.push(path(cell.data.vehicle_id))
      } else {
        const path = navigationPaths.approvalsShow
        this.props.history.push(path(cell.data.id))
      }
    }
  }

  noRowsTemplate() {
    return `<span> ${this.props.t("noAciveOrdersInFleet")} </span>`
  }

  saveFilterModel = () => {
    const oldFilter = this.state.filterModels || {}
    oldFilter[this.state.activePage] = this.api.getFilterModel()
    this.setState({ filterModels: oldFilter })
  }

  restorePageFilterModel = () =>
    this.api.setFilterModel(this.state.filterModels[this.state.activePage])
  setFilteredItems = () =>
    this.setState({ filteredItems: this.api.getModel().rowsToDisplay.map((r) => r.data) })
  resetFiltering = () => {
    this.api.setFilterModel([])
    this.setState({ orderFilter: null })
  }

  redrawGrid() {
    this.setState({ showGrid: false })
    setTimeout(() => this.setState({ showGrid: true }), 500)
  }

  externalFilterChanged = (newValue) => {
    this.setState({ dueFilter: newValue }, () => this.api.onFilterChanged())
  }

  isExternalFilterPresent = () => {
    // if dueFilter is not DUE_FILTER_ALL, then we are filtering
    return this.state.dueFilter != DUE_FILTER_ALL
  }

  doesExternalFilterPass = (node) => {
    const dueFilter = this.state.dueFilter
    switch (dueFilter) {
      case DUE_FILTER_DUE_SOON:
        return node.data.due_status === maintenanceHelpers.DUE_SOON
      case DUE_FILTER_PAST_DUE:
        return node.data.due_status === maintenanceHelpers.PAST_DUE
      default:
        return true
    }
  }

  componentDidMount() {
    logFirebaseAnalyticsScreenName(getRouteNameFromUrl())

    this.props.dispatch({
      type: "FLEET_ACTIVE_ORDERS_SAGA",
      payload: {},
      callback: this.afterRequestSuccess
    })
  }

  afterRequestSuccess = (status) => {
    status === "success" ? this.setState({ showGrid: true }) : this.setState({ showGrid: false })
  }

  render() {
    const { language, t, activeOrders = [] } = this.props
    const { pages, activePage, filteredItems, showGrid } = this.state
    const page = pages.find((p) => p.name === activePage)

    return (
      <div>
        <ApplicationLayout>
          <span />
          <FMCOrderHeader header={t("fmcDashboard:approvalsScreenLabel")} />
          <Segment.Group>
            <Segment className="menu-container">
              <Menu pointing secondary stackable>
                {pages.map((page) => (
                  <Menu.Item
                    name={t(page.label)}
                    active={page.name === activePage}
                    className="font-weight"
                    // onClick={this.handlePageChange.bind(this, page.name)}
                  />
                ))}
              </Menu>
            </Segment>
            <Segment style={{ border: "none" }}>
              <Grid columns="equal" className={"ag-theme-search"} stackable>
                <Grid.Column textAlign="right">
                  {
                    <Button className="white-button" onClick={this.resetFiltering}>
                      {t("resetFiltersLabel")}
                    </Button>
                  }
                </Grid.Column>
              </Grid>
            </Segment>
            <Segment
              className={"ag-theme-material tab-container"}
              style={{
                height: agGridHelpers.dynamicGridHeight({ activeOrders })
              }}
            >
              {showGrid ? (
                <AgGridReact
                  columnDefs={page.columnDefs.call(
                    this,
                    activePage,
                    this.servicesCellRenderer,
                    this.schedulerCellRenderer,
                    (cellParams) => this.statusCellRenderer(cellParams, t),
                    t
                  )}
                  defaultColDef={{
                    sortable: true,
                    filter: true,
                    headerComponentParams: {
                      template:
                        '<div class="ag-cell-label-container" role="presentation">' +
                        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                        '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                        '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                        "  </div>" +
                        "</div>"
                    }
                  }}
                  localeText={language === FR_LOCALE ? frGrid : enGrid}
                  rowData={page.rowData.call(this, activeOrders, language)}
                  rowSelection={"single"}
                  frameworkComponents={{
                    customNoRowsOverlay: CustomNoRowsOverlay
                  }}
                  onGridReady={this.onGridReady}
                  noRowsOverlayComponent={"customNoRowsOverlay"}
                  noRowsOverlayComponentParams={{
                    translationFile: "fmcDashboard",
                    label: "noAciveOrdersInFleet"
                  }}
                  cacheQuickFilter={true}
                  pagination={true}
                  paginationPageSize={agGridHelpers.PAGINATION_AMOUNT}
                  suppressMenuHide={true}
                  suppressMovableColumns={false}
                  suppressCellSelection={true}
                ></AgGridReact>
              ) : isFleetAdvise() ? (
                <FMCTransactionsShimmer />
              ) : (
                <LoadingThrobber visible />
              )}
            </Segment>
          </Segment.Group>
        </ApplicationLayout>
      </div>
    )
  }
} // class FMCActiveOrders

const mapStateToProps = (state) => {
  const { fleets, users } = state
  return {
    activeOrders: fleets.fleetActiveOrders,
    language: users.currentUser ? users.currentUser.language : ""
  }
}

export default connect(mapStateToProps, null)(withTranslation("services")(FMCActiveOrders))
