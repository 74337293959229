import React, { PureComponent } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import CarAdviseLogo from "../../assets/images/CA-Icon.svg"
import BarcodLogo from "../../assets/images/qr_code.png"
import { getStyles, getSubdomain } from "../../components/shared/StylesheetInjector"
import { CURRENT_USER_NAME, FLEET, FMC } from "../../constants/application"
import { DRIVER, MANAGER } from "../../constants/roles"
import {
  isDTCAFleet,
  isFleetMaintenanceHub,
  isFleetMaintenanceHubCanada,
  isNTWTBC,
  isFleetcor
} from "../../helpers/affiliationHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"
import ApplicationLayout from "../../layouts/ApplicationLayout"
import MembershipCard from "./MembershipCard"
import {
  AUTO_INTEGRATE,
  FLEET_ACCOUNT,
  HELP_CENTER_NAME,
  PHONE,
  WORLDWIDE_STORES,
  FLEETCOR_WORLDWIDE_STORES,
  getCompanyName
} from "../../constants/membershipCard"
import { gaJSON, logFirebaseAnalyticsScreenName } from "../../helpers/googleAnalyticsHelpers"
import { isFMCUser } from "../../helpers/userRoleHelpers"
import { isFmcPath } from "../../helpers/pathHelpers"

const styles = getStyles()
class MembershipPanel extends PureComponent {
  state = {
    hideChrome: false,
    vehicle: {}
  }

  hideChrome = async () => await this.setState({ hideChrome: true })

  showChrome = async () => await this.setState({ hideChrome: false })

  loadAllVehicles = async () => {
    const { id } = this.props.fleet
    const { currentUser } = this.props
    await this.props.dispatch({
      type: "VEHICLES_BASIC_LOAD_SAGA",
      payload: {
        options: {
          fleet_id:
            !(
              currentUser.roles &&
              currentUser.roles.length > 0 &&
              currentUser.roles.find((role) => role === MANAGER || role === DRIVER)
            ) && id,
          isFleetCard: true
        }
      }
    })
    this.getBasicUserDetails(currentUser.fleet_id)
  }

  getBasicUserDetails = (fleet_id) => {
    this.props.dispatch({
      type: "FETCH_USERS_BASIC_DETAILS",
      payload: { fleet_id: fleet_id }
    })
  }

  componentDidMount() {
    logFirebaseAnalyticsScreenName(gaJSON.membership_card)
    if (!this.props.isFMCMode) this.loadAllVehicles()
  }

  componentDidUpdate(prevProps) {
    const { fleetVehicles } = this.props
    if (prevProps.fleetVehicles !== this.props.fleetVehicles) {
      if (fleetVehicles && fleetVehicles.length > 0) {
        this.setState({ vehicle: fleetVehicles[0] })
      }
    }
  }

  getPhone = (sub) => {
    // TODO change to this when it goes live. 1-833-ZUBIESM
    const phones = {
      zubie: "1-844-923-8473"
    }

    if (!phones[sub]) return "1-844-923-8473"
    else return phones[sub]
  }

  getSelectedVehicle = (vehicle) => {
    this.setState({ vehicle: vehicle })
    this.getBasicUserDetails(vehicle.fleet_id)
  }
  render() {
    const { t, currentUser, fleet, fleetVehicles, isLoading, isFMCMode, isFleetMode } = this.props
    const { vehicle } = this.state
    const currentUserName = sessionHelpers.getApplicationCookie(CURRENT_USER_NAME)
    const sub = getSubdomain()
    const phone = this.getPhone(sub)
    let width = 160
    let height = 17
    let imgPath = `${sub}_card_logo.svg`
    if (isFleetMaintenanceHub() || isFleetMaintenanceHubCanada()) {
      width = 320
      height = 25
      imgPath = "shell_no_session_logo.svg"
    }

    if (isNTWTBC()) {
      width = 320
      height = 40
    }
    if (isDTCAFleet()) {
      width = 320
      height = 25
    }

    const panel = (
      <MembershipCard
        membershipName={vehicle.fleet_name}
        driverName={vehicle.driver_names}
        membershipLogo={CarAdviseLogo}
        membershipAccount={FLEET_ACCOUNT}
        membershipBarcode={BarcodLogo}
        membershipIntegrate={AUTO_INTEGRATE}
        phone={PHONE}
        helpCenterName={HELP_CENTER_NAME}
        worldwideStores={isFleetcor() ? FLEETCOR_WORLDWIDE_STORES : WORLDWIDE_STORES}
        sectionSubnote={`${t("membershipFleetCard:selected")} ${getCompanyName()} ${t(
          "membershipFleetCard:maintenance"
        )}`}
        fleetVehicles={
          fleetVehicles &&
          fleetVehicles.length > 0 &&
          fleetVehicles.filter((vehicle) => vehicle && vehicle.is_active)
        }
        selectedVehicle={vehicle}
        getSelectedVehicle={this.getSelectedVehicle}
        isLoading={isLoading}
        isFMCMode={isFMCMode}
        isFleetMode={isFleetMode}
        dispatch={this.props.dispatch}
      />
    )

    if (this.state.hideChrome) return panel
    else return <ApplicationLayout>{panel}</ApplicationLayout>
  }
}

const mapStateToProps = (state) => {
  const user = state.application.userSessionData || (state.users && state.users.currentUser)
  const isFMC = isFMCUser(user)
  const isPathFMC = isFmcPath()
  return {
    isFMCMode: state.application.mode === FMC && isFMC,
    isFleetMode: state.application.mode === FLEET || (isFMC && !isPathFMC),
    currentUser: state.application.userSessionData || state.users.currentUser,
    fleet: state.application.fleetSessionData || state.fleets.fleet,
    fleetVehicles: state.vehicles.basicVehicles,
    isLoading: state.application.isLoading
  }
}

export default connect(mapStateToProps)(withTranslation("membershipCard")(MembershipPanel))
