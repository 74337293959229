import { SplitFactory } from "@splitsoftware/splitio"

export async function setupSplitFlags(opts = {}) {
  if (window.cachedCartFlags && Object.keys(window.cachedCartFlags).length > 0) {
    this.setState({ ...this.state, ...window.cachedCartFlags })
    if (opts.callback != null) opts.callback(window.cachedCartFlags)
  } else {
    let { currentUser } = this.props
    let result = await SplitClient.getTreatmentsWithConfig(
      [
        GEOTAB_SECTION_FEATURE,
        GLOVEBOX_FEATURE,
        FUELMAN_MASTERCARD_FEATURE,
        FLEET_MULTIFACTOR_AUTHENTICATION_FEATURE,
        FLEET_CAPTCHA_FLAG,
        FOOTER_COMPONENT,
        MAINTENANCE_SCHEDULES,
        MAGIC_LINK_FEATURE,
        SHOW_TIRE_PROMOTION_BANNER,
        REPORTING_FLAG
      ],
      currentUser,
      opts
    )

    let cachedFlags = {
      isGeotabSectionFlag: result && result[GEOTAB_SECTION_FEATURE].treatment,
      isGloveboxFeatureFlag: result && result[GLOVEBOX_FEATURE].treatment,
      isFuelmanMastercardFlag: result && result[FUELMAN_MASTERCARD_FEATURE].treatment,
      isMultifactorAuthenticationFlag:
        result && result[FLEET_MULTIFACTOR_AUTHENTICATION_FEATURE].treatment,
      isFleetCaptchaFlag: result && result[FLEET_CAPTCHA_FLAG].treatment,
      showFooterComponent: result && result[FOOTER_COMPONENT].treatment,
      footerComponentConfig: result && JSON.parse(result[FOOTER_COMPONENT].config),
      isMaintenanceScheduleFlag: result && result[MAINTENANCE_SCHEDULES].treatment,
      isMagicLinkFeature: result && result[MAGIC_LINK_FEATURE].treatment,
      showTirePromotionBannerFlag: result && result[SHOW_TIRE_PROMOTION_BANNER].treatment,
      showTirePromotionBannerConfig:
        result && JSON.parse(result[SHOW_TIRE_PROMOTION_BANNER].config),
      showReportingFlag: result && result[REPORTING_FLAG].treatment
    }
    this.setState(cachedFlags)
    if (opts.callback != null) opts.callback(cachedFlags)
    window.cachedCartFlags = cachedFlags
  }
}

export async function getSplitClient({ customer }) {
  let env = "development"
  if (window.location.hostname.split(".")[1] === "development") env = "staging"
  else if (!window.location.hostname.match(/localhost/)) env = "production"
  let factory = SplitFactory({
    core: {
      authorizationKey: process.env.REACT_APP_BROWSER_API_KEY,
      key: customer && customer.id ? customer.id : process.env.REACT_APP_BROWSER_API_KEY
    }
  })
  return new Promise((resolve, reject) => {
    if (!window.splitIOClient) window.splitIOClient = factory.client()

    if (!window.splitSDKReady)
      window.splitIOClient.on(window.splitIOClient.Event.SDK_READY, () => {
        resolve(window.splitIOClient)
        window.splitSDKReady = true
      })
    else resolve(window.splitIOClient)
  })
}

export default class SplitClient {
  static async funcCaller(treatments, currentUser, opts, func) {
    if (!currentUser) return
    if (!window.splitClientFlagCache) window.splitClientFlagCache = {}

    let splitClient = await getSplitClient({ customer: currentUser })

    let result = splitClient[func](treatments, {
      id: currentUser.id,
      email: currentUser.email,
      affiliation_id: currentUser.affiliation_id,
      ...opts
    })

    return result
  }

  static async getTreatmentsWithConfig(treatments, currentUser, opts) {
    return this.funcCaller(treatments, currentUser, opts, "getTreatmentsWithConfig")
  }

  static async getTreatments(treatments, currentUser, opts) {
    return this.funcCaller(treatments, currentUser, opts, "getTreatments")
  }

  static async getTreatment(treatment, currentUser, opts) {
    return await this.getTreatments([treatment], currentUser, opts)
  }
}

export const GEOTAB_SECTION_FEATURE = "Fleet_Geotab_Section_Feature"
export const ON = "on"
export const OFF = "off"
export const FUELMAN_MASTERCARD_FEATURE = "Fuelman_MasterCard_SignUp_Feature"
export const GLOVEBOX_FEATURE = "Glovebox_Feature_Fleet_Web"
export const FLEET_MULTIFACTOR_AUTHENTICATION_FEATURE = "Fleet_Multifactor_Authentication_Feature"
export const FLEET_CAPTCHA_FLAG = "FLEET_CAPTCHA_FLAG"
export const FOOTER_COMPONENT = "Show_Footer_Component"
export const MAINTENANCE_SCHEDULES = "FLEET_Maintenance_Schedules"
export const MAGIC_LINK_FEATURE = "Magic_Link_Retail"
export const SHOW_TIRE_PROMOTION_BANNER = "Show_Tire_Promotion_Banner"
export const REPORTING_FLAG = "REPORTING_FLAG"
