import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import * as shopHelpers from "../../helpers/shopHelpers"
import * as vehicleHelpers from "../../helpers/vehicleHelpers"
import Select from "../../elements/Select"
import AddServiceRow from "./AddServiceRow"
import TextField from "../../elements/TextField"
import { Form, Input } from "semantic-ui-react"
import DatePicker from "react-datepicker"
import moment from "moment"
import { Date_Format } from "../../constants/years"
import { withTranslation } from "react-i18next"
import { isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"
import { DocumentUploader } from "../shared/DocumentUploader"
import CustomNotification from "../shared/CustomNotification"
import { NOTIFICATION_TYPE } from "../../constants/notification"
import { MAX_FILE_LENGTH, SUPPORTED_DOCUMENT_TYPES } from "../../constants/application"
import i18n from "../../utilities/i18n"
import { fileSizes } from "../../helpers/numberHelpers"
import { ZIP_CODE_REGEX, POSTAL_CODE_REGEX } from "../../utilities/stringHelpers"
import ErrorField from "../../elements/ErrorField"
import { isEmpty } from "lodash"
import ReactSVG from "react-svg"
import {
  LABOR_TOTAL,
  LABOR_TOTAL_ERROR,
  PRICE_ERROR,
  QUANTITY,
  QUANTITY_ERROR,
  SAVINGS,
  SAVINGS_ERROR,
  UNIT_PRICE
} from "../../constants/maintenanceHistoryForm"
import { IsNaN, convertTwoDigitDecimal } from "../../helpers/maintenanceHistoryHelpers"

let current = {
  odometer: null,
  odometer_units: "miles",
  name: "",
  service_id: null,
  custom_service_name: "",
  unit_price: 0,
  position: null,
  quantity: 1,
  parts_total: 0,
  labor_total: 0,
  savings: 0,
  total_price: 0,
  sub_total: 0,
  rowIndex: 0,
  service_error: false,
  price_error: false,
  quantity_error: false,
  labor_total_error: false,
  savings_error: false
}

class MaintenanceHistoryForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.props.previousFormData,
      serviceRows: [{ ...current }],
      maintenanceInvoiceDocumentURL: null,
      selectedFiles: [],
      canUploadMultipleFiles: true,
      deleteDocumentIds: [],
      zipCode: "",
      timerId: null,
      errors: {},
      services: this.props.services,
      shops: this.props.shops,
      validationErrors: {},
      custom_shop_name: ""
    }
  }

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    services: PropTypes.array.isRequired,
    shops: PropTypes.array.isRequired,
    vehicles: PropTypes.array.isRequired,
    isSubmitting: PropTypes.bool,
    gotoShopSearch: PropTypes.func.isRequired
  }

  static defaultProps = {
    isSubmitting: false
  }

  componentDidMount() {
    const { dispatch, manualMaintenanceId } = this.props
    if (manualMaintenanceId) this.fetchManualMaintenanceData(dispatch, manualMaintenanceId)
  }

  fetchManualMaintenanceData = (dispatch, manualMaintenanceId) => {
    this.setState({ maintenance_history_id: manualMaintenanceId })
    dispatch({
      type: "MAINTENANCE_HISTORY_LOAD_SAGA",
      payload: {
        id: manualMaintenanceId
      },
      callback: this.afterSuccessfullyLoad
    })
  }

  afterSuccessfullyLoad = () => {
    const isFleetShellCanada = isFleetMaintenanceHubCanada()
    const { services, dispatch } = this.props
    let {
      custom_shop_name,
      date_of_completion,
      vehicle_id,
      po_number,
      shop_id,
      customer_comments,
      order_services,
      maintenance_documents,
      zip
    } = this.props.maintenance_history
    const updatedArray =
      order_services &&
      order_services.map((obj, index) => {
        let { service_definition_id, ...rest } = obj
        if (obj.custom_service_name && obj.custom_service_name != "") {
          const newOption = {
            id: obj.custom_service_name,
            name: obj.custom_service_name,
            positions: []
          }
          this.updateServices(newOption)
          service_definition_id = obj.custom_service_name
        }
        return {
          ...obj,
          service_id: service_definition_id,
          rowIndex: index + 1,
          service: services.find((s) => s.id === service_definition_id),
          custom_service_name: obj.custom_service_name == null ? "" : obj.custom_service_name,
          quantity: convertTwoDigitDecimal(obj.qty),
          labor_total: convertTwoDigitDecimal(obj.labor_total),
          parts_total: convertTwoDigitDecimal(obj.parts_total),
          sub_total: convertTwoDigitDecimal(obj.sub_total),
          total_price: convertTwoDigitDecimal(obj.total_price),
          savings: convertTwoDigitDecimal(obj.savings),
          unit_price: convertTwoDigitDecimal(obj.unit_price)
        }
      })

    const updatedMaintenance_documents =
      maintenance_documents &&
      maintenance_documents.map((obj, index) => {
        return {
          ...obj,
          fileImage: obj.invoice_document_url && obj.invoice_document_url.url,
          fileName:
            obj.invoice_document_url &&
            new URL(obj.invoice_document_url.url).pathname.split("/").pop()
        }
      })

    var odometer
    {
      isFleetShellCanada
        ? (odometer = order_services && order_services[0].service_kilometers)
        : (odometer = order_services && order_services[0] && order_services[0].miles)
    }

    if (custom_shop_name) {
      const newOption = { id: custom_shop_name, name: custom_shop_name }
      this.updateShop(newOption)
      shop_id = custom_shop_name
    }

    this.setState({
      custom_shop_name: custom_shop_name || "",
      date_of_completion: moment(date_of_completion),
      vehicle_id: vehicle_id,
      po_number: po_number,
      shop_id: shop_id,
      customer_comments: customer_comments,
      serviceRows: updatedArray,
      selectedFiles: updatedMaintenance_documents,
      date_of_completion_pretty: moment(date_of_completion).format(Date_Format.YYYY_MM_DD_TIME),
      odometer: odometer,
      zipCode: zip
    })
    if (zip && !custom_shop_name) this.filterShops(dispatch, zip)
  }

  isAnyServiceNull(serviceRows) {
    return serviceRows.map((row) => row.service_id).includes(null)
  }

  isAnyServicePriceNull(serviceRows) {
    return serviceRows.some((servicerRow) => servicerRow.price === "" || servicerRow.price === null)
  }

  allowSubmit() {
    const { shop_id, vehicle_id, odometer, date_of_completion, serviceRows } = this.state
    const errors = {
      shop_id: !shop_id,
      vehicle_id: !vehicle_id,
      odometer: !odometer,
      date_of_completion: !date_of_completion
    }

    const invalidServiceRows = serviceRows.filter(
      (serviceRow) => !serviceRow.service_id || serviceRow.unit_price <= 0
    )

    const updatedServiceRows = serviceRows.map((serviceRow) => ({
      ...serviceRow,
      service_error: !serviceRow.service_id,
      price_error: serviceRow.unit_price === "" || IsNaN(serviceRow.unit_price),
      quantity_error:
        serviceRow.quantity === "" || serviceRow.quantity < 1 || IsNaN(serviceRow.quantity),
      labor_total_error: serviceRow.labor_total === "" || IsNaN(serviceRow.labor_total),
      savings_error: serviceRow.savings === "" || IsNaN(serviceRow.savings)
    }))

    this.setState({
      validationErrors: errors,
      serviceRows: updatedServiceRows
    })

    return (
      Object.values(errors).every((error) => error === false) &&
      invalidServiceRows.length === 0 &&
      !updatedServiceRows.some(
        (item) =>
          item.savings_error || item.labor_total_error || item.quantity_error || item.price_error
      )
    )
  }

  formattedFormData() {
    const {
      vehicle_id,
      odometer,
      date_of_completion_pretty,
      shop_id,
      custom_shop_name,
      po_number,
      customer_comments,
      serviceRows,
      selectedFiles,
      deleteDocumentIds,
      zipCode
    } = this.state

    const { manualMaintenanceId } = this.props
    const formData = new FormData()

    formData.append("vehicle_id", JSON.stringify(vehicle_id))
    if (zipCode) formData.append("zip", zipCode)

    if (custom_shop_name) {
      formData.append("custom_shop_name", custom_shop_name)
      formData.append("shop_id", "")
    } else {
      formData.append("shop_id", JSON.stringify(shop_id))
      formData.append("custom_shop_name", "")
    }
    if (po_number) formData.append("po_number", po_number)
    if (customer_comments) formData.append("customer_comments", customer_comments)

    formData.append("odometer", JSON.stringify(odometer))
    formData.append("date_of_completion", date_of_completion_pretty)
    formData.append("with_manual_history", JSON.stringify(true))

    serviceRows.forEach((serviceRow, index) => {
      const {
        id,
        unit_price,
        quantity,
        labor_total,
        parts_total,
        sub_total,
        total_price,
        savings,
        odometer_units,
        name,
        custom_service_name,
        service_id,
        position,
        price
      } = serviceRow

      formData.append(`vehicle_services[][id]`, id)
      formData.append(`vehicle_services[][odometer]`, JSON.stringify(odometer))
      formData.append(`vehicle_services[][unit_price]`, convertTwoDigitDecimal(unit_price))
      formData.append(`vehicle_services[][qty]`, convertTwoDigitDecimal(quantity))
      formData.append(`vehicle_services[][labor_total]`, convertTwoDigitDecimal(labor_total))
      formData.append(`vehicle_services[][parts_total]`, convertTwoDigitDecimal(parts_total))
      formData.append(`vehicle_services[][sub_total]`, convertTwoDigitDecimal(sub_total))
      formData.append(`vehicle_services[][total_price]`, convertTwoDigitDecimal(total_price))
      formData.append(`vehicle_services[][savings]`, convertTwoDigitDecimal(savings))
      formData.append(`vehicle_services[][odometer_units]`, JSON.stringify(odometer_units))
      formData.append(`vehicle_services[][name]`, JSON.stringify(name))
      formData.append(`vehicle_services[][serviceDefinition]`, null)

      if (custom_service_name !== "") {
        formData.append(`vehicle_services[][custom_service_name]`, custom_service_name)
      } else {
        formData.append(`vehicle_services[][service_definition_id]`, JSON.stringify(service_id))
        formData.append(`vehicle_services[][custom_service_name]`, "")
      }

      if (position) {
        formData.append(`vehicle_services[][position]`, position)
      }

      formData.append(`vehicle_services[][price]`, price)
      formData.append(`vehicle_services[][date_of_completion]`, date_of_completion_pretty)
    })

    deleteDocumentIds.forEach((docId, index) => {
      formData.append(`maintenance_documents_attributes[][id]`, docId)
      formData.append(`maintenance_documents_attributes[][_destroy]`, true)
    })

    if (selectedFiles.length > 0) {
      const key = manualMaintenanceId
        ? "new_maintenance_documents_attributes[][invoice_document_url]"
        : "maintenance_documents_attributes[][invoice_document_url]"

      selectedFiles.forEach((file, index) => {
        file.file && formData.append(key, file.file)
      })
    }

    return {
      formData,
      vehicle_id
    }
  }

  checkValidationAndSubmit = async () => {
    if (!this.props.isSubmitting && this.allowSubmit()) {
      this.props.onSubmit(this.formattedFormData(), this.props.manualMaintenanceId)
    }
  }

  onChange = async (field, value) => {
    if (value < 0) value = 0
    if (field === "date_of_completion") {
      this.setState({
        [field]: value === "" ? undefined : value,
        [`${field}_pretty`]: value === "" ? undefined : value.format(Date_Format.YYYY_MM_DD_TIME)
      })
    } else {
      if (field == "shop_id") {
        let shop = this.state.shops.find((s) => s.id == parseInt(value) || s.id == value)
        if (!shop) {
          const newOption = { id: value, name: value }
          await this.updateShop(newOption)
          this.setState({ custom_shop_name: value })
        } else this.setState({ custom_shop_name: "" })
      }
      this.setState({ [field]: value === "" ? undefined : value })
    }
    await this.updateState(field, value)
  }

  filterShopsParams() {
    let { vehicle_id, serviceRows } = this.state
    let vehicle_type_id
    if (this.state.vehicle) vehicle_type_id = this.state.vehicle.vehicle_type_id
    return {
      vehicle_id,
      vehicle_type_id,
      services_requested: serviceRows.map((service) => {
        return {
          position: service.position,
          service_definition_id: service.id
        }
      })
    }
  }

  validateFields = (name, value) => {
    switch (name) {
      case "zipCode":
        if (isFleetMaintenanceHubCanada()) return POSTAL_CODE_REGEX.test(value)
        else return ZIP_CODE_REGEX.test(value)
    }
  }

  onZipChange = (value) => {
    const { dispatch, t } = this.props
    this.setState({ zipCode: value })

    if (this.validateFields("zipCode", value)) {
      this.setState({
        errors: {
          zipCode: ""
        }
      })
      clearTimeout(this.state.timerId)

      const newTimerId = setTimeout(() => {
        this.filterShops(dispatch, value)
      }, 2000)

      this.setState({ timerId: newTimerId, custom_shop_name: "" })
    } else {
      if (value.length == 0) {
        this.setState({
          errors: {
            zipCode: ""
          }
        })
      } else {
        this.setState({
          errors: {
            zipCode: isFleetMaintenanceHubCanada()
              ? t("postalCodeValidationLabel")
              : t("zipCodeValidationLabel")
          }
        })
      }
      this.setState({ shops: [] })
      dispatch({ type: "SHOPS_FILTER_SUCCEEDED", payload: { shops: [], pageCount: 1 } })
    }
  }

  filterShops = (dispatch, value) => {
    this.setState({ shopsLoading: true })
    dispatch({
      type: "SHOPS_FILTER_SAGA",
      payload: {
        asSubForm: true,
        fromMaintananceHistory: true,
        filterShopsParams: this.filterShopsParams(),
        filters: {
          searchLocation: {
            zip: value
          }
        }
      },
      callback: this.afterShopFilter
    })
  }

  afterShopFilter = (status, shops) => {
    this.setState({ shops: shops.shops })
    this.setState({ shopsLoading: false })
  }

  onVehicleChange(value) {
    if (value) {
      this.setState({
        vehicle_id: value,
        vehicle: this.props.vehicles.find((s) => s.id == parseInt(value))
      })
    } else this.setState({ vehicle_id: null })
    this.setState((prevState) => ({
      validationErrors: {
        ...prevState.validationErrors,
        ["vehicle_id"]: value ? false : true
      }
    }))
  }

  onServiceChange = async (value, rowIndex) => {
    let serviceRows = [...this.state.serviceRows]
    if (value) {
      let selectedService = this.state.services.find(
        (s) => s.id == parseInt(value) || s.id == value
      )
      if (!selectedService) {
        this.handleCurrentChange("custom_service_name", value, rowIndex)
        const newOption = { id: value, name: value, positions: [] }
        await this.updateServices(newOption)
      } else this.handleCurrentChange("custom_service_name", "", rowIndex)

      selectedService = this.state.services.find((s) => s.id == parseInt(value) || s.id == value)
      serviceRows.forEach((serviceRow) => {
        if (serviceRow.rowIndex === rowIndex) {
          let resetPosition
          if (selectedService && isEmpty(selectedService.positions)) resetPosition = ""

          return Object.assign(serviceRow, {
            service_id: selectedService.id,
            name: selectedService.name,
            service: selectedService,
            position: resetPosition,
            service_error: false
          })
        }
      })
      this.setState({ ...this.state, serviceRows: serviceRows })
    } else {
      this.handleCurrentChange("service_error", true, rowIndex)
      this.handleCurrentChange("service_id", null, rowIndex)
      this.handleCurrentChange("custom_service_name", "", rowIndex)
    }
  }

  updateServices = async (service) => {
    this.setState((prevState) => ({
      services: [...prevState.services, service]
    }))
  }

  updateShop = async (shop) => {
    this.setState((prevState) => ({
      shops: [...prevState.shops, shop]
    }))
  }

  updateState = async (field, value) => {
    this.setState((prevState) => ({
      validationErrors: {
        ...prevState.validationErrors,
        [field]: value == undefined || value == "" || value == 0 ? true : false
      }
    }))
  }

  handleCurrentChange = (field, value, rowIndex) => {
    if (value < 0) value = 0
    let { serviceRows } = this.state
    serviceRows.forEach((serviceRow) => {
      if (serviceRow.rowIndex === rowIndex) {
        if (field === UNIT_PRICE && value >= 0) {
          serviceRow[PRICE_ERROR] = false
        }
        if (field === QUANTITY && value > 0) {
          serviceRow[QUANTITY_ERROR] = false
        }
        if (field === LABOR_TOTAL && value >= 0) {
          serviceRow[LABOR_TOTAL_ERROR] = false
        }
        if (field === SAVINGS && value >= 0) {
          serviceRow[SAVINGS_ERROR] = false
        }

        serviceRow[field] = value

        const partsTotal = parseFloat(serviceRow.quantity) * parseFloat(serviceRow.unit_price || 0)
        const subTotal = partsTotal + parseFloat(serviceRow.labor_total || 0)
        const totalPrice = Math.max(subTotal - parseFloat(serviceRow.savings || 0), 0)

        return Object.assign(serviceRow, {
          parts_total: partsTotal || 0,
          sub_total: subTotal || 0,
          total_price: totalPrice,
          unit_price: serviceRow.unit_price,
          labor_total: serviceRow.labor_total,
          quantity: serviceRow.quantity,
          savings: serviceRow.savings
        })
      }
    })
    this.setState({ serviceRows })
  }

  renderAddServiceRows(serviceRows) {
    if (serviceRows && serviceRows.length > 0) {
      return serviceRows.map((serviceRow, index) => {
        return (
          <AddServiceRow
            key={index}
            serviceRow={serviceRow}
            services={this.state.services}
            onServiceChange={this.onServiceChange}
            handleCurrentChange={this.handleCurrentChange}
            handleRemove={this.handleRemove}
            maintenance_history_id={this.state.maintenance_history_id}
            {...this.state}
            validationErrors={this.state.validationErrors}
          />
        )
      })
    }
  }

  addMoreService() {
    let { serviceRows } = this.state
    const lastRow = serviceRows.slice(-1)[0]
    let rowIndex = lastRow ? lastRow.rowIndex + 1 : 0

    serviceRows = [...serviceRows, { ...current, rowIndex }]
    this.setState({ serviceRows })
  }

  handleRemove = async (rowIndex) => {
    let { serviceRows } = this.state
    serviceRows = [...serviceRows.filter((service) => service.rowIndex !== rowIndex)]
    await this.setState({ serviceRows })
  }

  handleFileChange = (files) => {
    const { canUploadMultipleFiles, selectedFiles } = this.state
    const fileCount = [...files, ...selectedFiles].length
    if (
      (canUploadMultipleFiles && files.length > MAX_FILE_LENGTH && fileCount > MAX_FILE_LENGTH) ||
      selectedFiles.length >= MAX_FILE_LENGTH ||
      fileCount > MAX_FILE_LENGTH
    ) {
      CustomNotification(
        NOTIFICATION_TYPE.DANGER,
        i18n.t("settings:errorLabel"),
        `${i18n.t("vehicle:maxFilesLengthErrorLabel", { maxFiles: MAX_FILE_LENGTH })}`,
        i18n.t
      )
      return
    }

    for (let i = 0; i < files.length; i++) {
      let file = files[i]

      if (SUPPORTED_DOCUMENT_TYPES.includes(file.type)) {
        if (file.size >= 5000000) {
          CustomNotification(
            NOTIFICATION_TYPE.DANGER,
            i18n.t("settings:errorLabel"),
            i18n.t("vehicle:invoiceFileSizeLimitLabel"),
            i18n.t
          )
          return
        }
        let reader = new FileReader()
        // For Multiple File Input
        if (canUploadMultipleFiles) {
          if (selectedFiles.some((selectedFile) => selectedFile.fileName === file.name)) {
            CustomNotification(
              NOTIFICATION_TYPE.DANGER,
              i18n.t("settings:errorLabel"),
              i18n.t("vehicle:duplicateFileErrorLabel", { fileName: file.name }),
              i18n.t
            )
            continue
          } else {
            reader.onloadend = () => {
              this.setState((prevState) => ({
                selectedFiles: [
                  ...prevState.selectedFiles,
                  {
                    file: file,
                    fileName: file.name,
                    fileType: file.type,
                    fileImage: reader.result,
                    dateTime:
                      file.lastModifiedDate && file.lastModifiedDate.toLocaleString("en-US"),
                    fileSize: fileSizes(file.size),
                    url: URL.createObjectURL(file)
                  }
                ]
              }))
            }
            if (files[i]) {
              reader.readAsDataURL(file)
            }
          }
        } else {
          // For Single File Input
          reader.onloadend = () => {
            this.setState({
              selectedFiles: [
                {
                  fileName: file.name,
                  fileType: file.type,
                  fileImage: reader.result,
                  dateTime: file.lastModifiedDate && file.lastModifiedDate.toLocaleString("en-US"),
                  fileSize: fileSizes(file.size),
                  url: URL.createObjectURL(file)
                }
              ]
            })
          }

          if (files[0]) {
            reader.readAsDataURL(file)
          }
        }
      } else {
        CustomNotification(
          NOTIFICATION_TYPE.DANGER,
          i18n.t("settings:errorLabel"),
          i18n.t("vehicle:fileTypeErrorLabel"),
          i18n.t
        )
      }
    }
  }

  deleteSelectedFile = (id, document_id) => {
    const { maintenance_history } = this.props
    if (maintenance_history && maintenance_history.id && document_id) {
      this.setState({ deleteDocumentIds: [...this.state.deleteDocumentIds, document_id] })
    }
    const { selectedFiles } = this.state
    this.setState((prevState) => {
      const updatedFiles = prevState.selectedFiles.filter((item, i) => i !== id)
      return {
        selectedFiles: updatedFiles
      }
    })
  }

  render() {
    const { vehicles, t, manualMaintenanceId, isSubmitting } = this.props
    const {
      shop_id,
      vehicle_id,
      odometer,
      date_of_completion,
      po_number,
      customer_comments,
      serviceRows,
      selectedFiles,
      canUploadMultipleFiles,
      zipCode,
      errors,
      shopsLoading,
      shops,
      validationErrors
    } = this.state
    return (
      <div>
        <Form>
          <div className="manual-maintenance-history-container">
            <div className="mmh-header-section">
              <div className="mmh-header">{t("manualMaintenanceHistoryHeader")}</div>
              <div></div>
            </div>

            <div className="mmh-vehicle-info-section">
              <div className="mmh-header">{t("vehicleInfoLable")}</div>
              <div className="mmh-vehicle-content">
                <div className="mmh-vehicle-info-fields">
                  <Form.Field required style={{ width: "99%" }}>
                    <label>{t("Vehicle")}</label>
                    <Select
                      onChange={(event) => this.onVehicleChange(event.target.value)}
                      options={vehicleHelpers.selectOptions(vehicles)}
                      placeholder={t("selectVehicleLabel")}
                      defaultValue={vehicle_id}
                      value={vehicle_id}
                      className={validationErrors.vehicle_id ? "error-message" : ""}
                    />
                  </Form.Field>
                </div>
                <div className="mmh-vehicle-info-fields">
                  <Form.Field required style={{ width: "100%" }}>
                    <label>{t("odometerLable")}</label>
                    <Input
                      onChange={(event) => this.onChange("odometer", event.target.value)}
                      placeholder={t("enterOdometerLabel")}
                      value={odometer}
                      type="number"
                      className={validationErrors.odometer ? "error-message" : ""}
                    />
                  </Form.Field>
                </div>
              </div>
            </div>

            <div className="mmh-vehicle-info-section">
              <div className="mmh-header">{t("shopInfoLable")}</div>
              <div className="mmh-vehicle-content">
                <div className="mmh-vehicle-info-fields">
                  <Form.Field style={{ width: "100%" }}>
                    <label>{t("zipCodeLabel")}</label>
                    <TextField
                      onChange={(event) => this.onZipChange(event.target.value)}
                      placeholder={
                        isFleetMaintenanceHubCanada() ? t("codePostalLabel") : t("zipCodeLabel")
                      }
                      value={zipCode}
                      title={t("searchShopByZipLabel")}
                    />
                    <ErrorField
                      displayError={errors.zipCode}
                      className={"error-field"}
                      label={errors.zipCode}
                    />
                  </Form.Field>
                </div>
                <div className="mmh-vehicle-info-fields">
                  <Form.Field required={true} style={{ width: "99%" }}>
                    <label>{t("shopLable")}</label>
                    <Select
                      onChange={(event) => this.onChange("shop_id", event.target.value)}
                      options={[{ value: undefined, label: t("manualEnterShopLabel") }].concat(
                        shopHelpers.selectOptions(shops)
                      )}
                      placeholder={shops.length == 0 ? t("noShopsLabel") : t("selectShopLabel")}
                      defaultValue={shop_id}
                      value={shop_id}
                      loading={shopsLoading}
                      className={validationErrors.shop_id ? "error-message" : ""}
                      allowAdditions={true}
                    />
                  </Form.Field>
                </div>
                <div className="mmh-vehicle-info-fields">
                  <Form.Field style={{ width: "100%" }}>
                    <label>{t("ponumberLable")}</label>
                    <TextField
                      onChange={(event) => this.onChange("po_number", event.target.value)}
                      placeholder={t("poNumberLabel")}
                      value={po_number}
                    />
                  </Form.Field>
                </div>
              </div>
              <DocumentUploader
                canUploadMultipleFiles={canUploadMultipleFiles}
                selectedFiles={selectedFiles}
                handleFileChange={this.handleFileChange}
                deleteSelectedFile={this.deleteSelectedFile}
              />
            </div>

            <div className="mmh-vehicle-service-section">
              <div className="mmh-header">{t("serviceInfoLable")}</div>
              {this.renderAddServiceRows(serviceRows)}
            </div>
            <div className="mmh-add-more-button" onClick={() => this.addMoreService()}>
              <ReactSVG src={`/images/plus.svg`} />
              {t("addMoreLabel")}
            </div>

            <div className="mmh-vehicle-info-section">
              <div className="mmh-header">{t("otherInfo")}</div>
              <div className="mmh-vehicle-content">
                <div className="mmh-vehicle-info-fields">
                  <Form.Field required style={{ width: "99%" }}>
                    <label>{t("dateOfCompletionLabel")}</label>
                    <DatePicker
                      selected={date_of_completion}
                      onChange={(value) => this.onChange("date_of_completion", value)}
                      maxDate={moment()}
                      dateFormat={
                        isFleetMaintenanceHubCanada()
                          ? Date_Format.YYYY_MM_DD
                          : Date_Format.MM_DD_YYYY
                      }
                      className={validationErrors.date_of_completion ? "error-message" : ""}
                      placeholderText={t("dateOfCompletionLabel")}
                      customInput={<Input value={date_of_completion} />}
                    />
                  </Form.Field>
                </div>
                <div className="mmh-vehicle-info-fields">
                  <Form.Field style={{ width: "100%" }}>
                    <label>{t("customerCommentsLabel")}</label>
                    <TextField
                      onChange={(event) => this.onChange("customer_comments", event.target.value)}
                      placeholder={t("customerCommentsLabel")}
                      value={customer_comments}
                    />
                  </Form.Field>
                </div>
              </div>
            </div>

            <div className={"mmh-save-button-container"}>
              <div
                className={isSubmitting ? "mmh-save-button disabled" : "mmh-save-button"}
                onClick={() => this.checkValidationAndSubmit()}
              >
                {!isSubmitting ? <ReactSVG src={`/images/save.svg`} /> : null}
                {isSubmitting ? (
                  <>
                    {t("savingLoadingLable")}
                    <div className={"mini-loader"}></div>
                  </>
                ) : manualMaintenanceId ? (
                  t("common:updateLabel")
                ) : (
                  t("common:saveLabel")
                )}
              </div>
            </div>
          </div>
        </Form>
      </div>
    )
  }
} // class MaintenanceHistoryForm

const mapStateToProps = (state) => {
  return {
    services: state.services.services,
    vehicles: state.vehicles.vehicles,
    shops: state.shops.shops,
    maintenance_history: state.maintenanceHistories.manualMaintenanceHistory || []
  }
}

export default connect(mapStateToProps)(
  withTranslation("maintenanceHistory")(MaintenanceHistoryForm)
)
