import React, { Component } from "react"
import PropTypes from "prop-types"
import {
  SERVICE_COMPLETED,
  SERVICE_APPROVED,
  SERVICE_AWAITING_APPROVAL,
  SERVICE_INITIATED,
  SERVICE_REJECTED,
  SERVICE_DELETED
} from "../../constants/statuses"
import { MANUAL_MAINTENANCE_TYPE } from "../../constants/services"
import moment from "moment"
import * as numberHelpers from "../../helpers/numberHelpers"
import { Grid, Image, Modal, Table } from "semantic-ui-react"

import ScheduleServiceButton from "../shared/ScheduleServiceButton"
import { showMaintenanceHistoryEditDelete } from "../../helpers/activeMaintenanceHelpers"
import { Date_Format } from "../../constants/years"
import { isFleetAdvise, isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"
import { withTranslation } from "react-i18next"
import { navigationPaths } from "../../constants/paths"
import activeIcon from "../../assets/images/custom_document.svg"
import disabledIcon from "../../assets/images/custom_document.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import FileDownloader from "../shared/FileDownloader"
import { faFileAlt } from "@fortawesome/pro-light-svg-icons"
import { handleDownload } from "../../helpers/documentHelpers"
import { Link } from "react-router-dom"

class MaintenanceHistoryRecord extends Component {
  static propTypes = {
    record: PropTypes.object.isRequired
  }

  formattedDate(language) {
    const {
      record: { appointment_datetime = "" }
    } = this.props

    return isFleetMaintenanceHubCanada()
      ? moment.utc(appointment_datetime).format(Date_Format.YYYY_MM_DD)
      : moment.utc(appointment_datetime).format(Date_Format.MM_DD_YYYY)
  }

  formattedShopInfo() {
    const { shop, custom_shop_name } = this.props.record
    if (custom_shop_name) return custom_shop_name
    if (!shop) return ""

    return `${shop.name} ${shop.city}, ${shop.state}`
  }

  savedServices() {
    return this.props.record.order_services.filter(
      (os) => os.status === SERVICE_REJECTED || os.status === SERVICE_DELETED
    )
  }

  completedServices() {
    return this.props.record.order_services.filter((os) => {
      return (
        [SERVICE_APPROVED, SERVICE_AWAITING_APPROVAL, SERVICE_INITIATED, SERVICE_COMPLETED].indexOf(
          os.status
        ) !== -1 || !os.status
      )
    })
  }

  checkForActiveOrder(event) {
    const { active_order } = this.props.vehicle

    if (active_order) {
      event.preventDefault()
      alert(
        "There is already an active order for this vehicle. Please complete before you schedule."
      )
    }
  }

  onDelete = (recordId) => {
    const { id } = this.props.record
    const confirmed = window.confirm("Are you sure you want to delete?")

    if (confirmed) {
      this.props.dispatch({
        type: "MAINTENANCE_HISTORY_DELETE_SAGA",
        payload: {
          recordId: id
        },
        callback: this.props.afterDeleteMaintenanceHistory
      })
    }
  }

  callToActionRenderer = (cellParams) => {
    const { id, maintenance_type } = cellParams
    if (maintenance_type !== MANUAL_MAINTENANCE_TYPE) {
      const path = navigationPaths.approvalsShow

      return <div>{this.props.history.push(path(id))}</div>
    }
  }

  showSavedDocuments = (documents) => {
    const { t } = this.props
    let documentCount = documents && documents.length
    let files = []
    if (documents && documents.length > 0) {
      documents.forEach((doc) => {
        files.push(doc.invoice_document_url)
      })
    }
    return (
      <Modal
        size="small"
        dimmer="blurring"
        closeIcon
        closeOnDimmerClick={false}
        trigger={
          <span
            style={
              documentCount !== 0 && documentCount !== undefined
                ? { cursor: "pointer" }
                : { pointerEvents: "none", cursor: "not-allowed" }
            }
          >
            <span>
              <Image
                src={documentCount === 0 && documentCount === undefined ? disabledIcon : activeIcon}
                style={
                  documentCount !== 0 && documentCount !== undefined
                    ? { cursor: "pointer" }
                    : { opacity: "0.5", cursor: "not-allowed" }
                }
                verticalAlign="middle"
              />
            </span>
            {documentCount > 0 && documentCount !== undefined ? (
              <span className="document-count">{documentCount}</span>
            ) : (
              <span className="document-count-null"> </span>
            )}
          </span>
        }
      >
        <Modal.Header>
          {t("invoiceDocumentsLabel")}
          <span>{files.length > 0 && <FileDownloader files={files} t={t} />}</span>
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description key={document.id}>
            <Grid columns={3} doubling>
              {documents &&
                documents.map((document, index) => {
                  const fileName =
                    document.invoice_document_url &&
                    new URL(document.invoice_document_url.url).pathname.split("/").pop()
                  const extension =
                    document.invoice_document_url &&
                    document.invoice_document_url.url.split("?")[0].split(".").pop()
                  let file = []
                  file.push(document.invoice_document_url)
                  return (
                    <Grid.Column key={document.id}>
                      <div className="invoice-documents">
                        <div className="invoice-document-name">
                          <span style={{ marginRight: "10px" }}>{index + 1}.</span>
                          <span>{fileName}</span>
                        </div>
                        <div className="file-atc-box" key={index}>
                          {fileName.match(/.(jpg|jpeg|png|jfif)$/i) ? (
                            <div className="file-image">
                              <Image
                                src={document.invoice_document_url.url}
                                alt=""
                                crossOrigin="anonymous"
                              />
                            </div>
                          ) : (
                            <div className="file-image">
                              <FontAwesomeIcon icon={faFileAlt} />
                            </div>
                          )}
                          <div className="file-detail">
                            <div className="file-actions">
                              <button
                                type="button"
                                className={`file-action-btn ${
                                  isFleetAdvise() ? "file-action-btn-background" : ""
                                }`}
                                onClick={() => handleDownload(file)}
                              >
                                {t("downloadLabel")}
                              </button>
                            </div>
                            <div className="preview-link">
                              <a
                                href={document.invoice_document_url.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {t("previewLabel")}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid.Column>
                  )
                })}
            </Grid>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    )
  }

  render() {
    const {
      vehicle,
      record,
      t,
      currentUser: { language = "" }
    } = this.props

    const completed = this.completedServices()
    const saved = this.savedServices()
    const total = completed
      .reduce(
        (acc, r) => (acc += parseFloat(r.total || r.total_price ? r.total || r.total_price : 0)),
        0
      )
      .toFixed(2)
    const isFleetShellCanada = isFleetMaintenanceHubCanada()

    return (
      <Table.Row
        className={
          record.maintenance_type !== MANUAL_MAINTENANCE_TYPE ? "maintenance-history-links" : ""
        }
      >
        <Table.Cell onClick={() => this.callToActionRenderer(record)} style={{ width: "11%" }}>
          {record.id}
        </Table.Cell>
        <Table.Cell onClick={() => this.callToActionRenderer(record)} style={{ width: "11%" }}>
          {this.formattedDate(language)}
        </Table.Cell>
        <Table.Cell onClick={() => this.callToActionRenderer(record)} singeLine>
          {this.formattedShopInfo()}
        </Table.Cell>
        <Table.Cell onClick={() => this.callToActionRenderer(record)}>
          {isFleetShellCanada
            ? numberHelpers.formatWithCommas(completed[0] && completed[0].service_kilometers)
            : numberHelpers.formatWithCommas(
                completed[0] && (completed[0].service_miles || completed[0].miles)
              )}
        </Table.Cell>
        <Table.Cell onClick={() => this.callToActionRenderer(record)}>
          {completed.map((service) => service.name).join(", ")}
        </Table.Cell>
        <Table.Cell onClick={() => this.callToActionRenderer(record)}>
          {saved.map((service) => service.name).join(", ")}
        </Table.Cell>
        <Table.Cell onClick={() => this.callToActionRenderer(record)}>
          {isFleetShellCanada ? `$${total} CAD` : `$${total}`}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {record.maintenance_type == MANUAL_MAINTENANCE_TYPE &&
            this.showSavedDocuments(record.maintenance_documents)}
        </Table.Cell>
        <Table.Cell singleLine>
          {saved.length ? (
            <ScheduleServiceButton
              vehicle={vehicle}
              maintenance={saved}
              text={t("scheduleSavedServicesLabel")}
            />
          ) : null}
        </Table.Cell>
        {showMaintenanceHistoryEditDelete(this.props.currentUser) &&
          record.maintenance_type == MANUAL_MAINTENANCE_TYPE && (
            <>
              <Table.Cell>
                <Link
                  to={{
                    pathname: navigationPaths.maintenanceHistoryEdit(record.id)
                  }}
                >
                  <span className="link">{t("editLabel")}</span>
                </Link>
              </Table.Cell>
              <Table.Cell>
                <span className="link" onClick={this.onDelete}>
                  {t("deleteLabel")}
                </span>
              </Table.Cell>
            </>
          )}
      </Table.Row>
    )
  }
} // class MaintenanceHistoryRecord

export default withTranslation("common")(MaintenanceHistoryRecord)
