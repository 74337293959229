export const QUANTITY = "quantity"
export const QUANTITY_ERROR = "quantity_error"
export const LABOR_TOTAL = "labor_total"
export const LABOR_TOTAL_ERROR = "labor_total_error"
export const SAVINGS = "savings"
export const SAVINGS_ERROR = "savings_error"
export const UNIT_PRICE = "unit_price"
export const PRICE_ERROR = "price_error"
export const REMOVE_INITIAL_ZERO_REGEX = /^0(?=\d)/
export const TWO_DIGIT_DECIMAL_ALLOW_REGEX = /^\d*\.?\d{0,2}$/
