import React, { useState, useRef, useLayoutEffect } from "react"
import { faAngleDown, faAngleUp } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Form, Input } from "semantic-ui-react"
import { withTranslation } from "react-i18next"
import ReactSVG from "react-svg"
import {
  convertTwoDigitDecimal,
  removeInitialZeros,
  roundUpTwoDecimals
} from "../../helpers/maintenanceHistoryHelpers"
import { LABOR_TOTAL, QUANTITY, SAVINGS } from "../../constants/maintenanceHistoryForm"

const HistoryAccordion = (props) => {
  const { isDefaultOpen, rowIndex, t, serviceRow } = props
  const [isExpanded, setIsExpanded] = useState(isDefaultOpen)
  const accordionRef = useRef(null)

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded)
  }

  useLayoutEffect(() => {
    if (isExpanded && accordionRef.current) {
      accordionRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }, [isExpanded])

  return (
    <div className={`history-accordion ${isExpanded ? "expanded" : ""}`} ref={accordionRef}>
      <div className="accordion-header" onClick={toggleAccordion}>
        <div className="history-label">
          {t("otherDetailsLable")}
          <ReactSVG src={isExpanded ? `/images/vector_up.svg` : `/images/vector_down.svg`} />
        </div>
      </div>
      {isExpanded && (
        <div className="accordion-content ">
          <div className="mmh-vehicle-info-section">
            <div className="mmh-vehicle-content">
              <div className="mmh-vehicle-info-fields">
                <Form.Field style={{ width: "100%" }}>
                  <label>{t("quantityLable")}</label>
                  <Input
                    onChange={(event) => {
                      if (roundUpTwoDecimals(event.target.value)) {
                        props.handleCurrentChange(QUANTITY, event.target.value, rowIndex)
                      }
                    }}
                    value={removeInitialZeros(serviceRow.quantity)}
                    type="number"
                    min="0"
                    className={serviceRow.quantity_error ? "error-message" : ""}
                  />
                </Form.Field>
              </div>
              <div className="mmh-vehicle-info-fields">
                <Form.Field style={{ width: "100%" }}>
                  <label>{t("laborTotalLable")}</label>
                  <Input
                    onChange={(event) => {
                      if (roundUpTwoDecimals(event.target.value)) {
                        props.handleCurrentChange(LABOR_TOTAL, event.target.value, rowIndex)
                      }
                    }}
                    placeholder="0.0"
                    value={removeInitialZeros(serviceRow.labor_total)}
                    type="number"
                    icon="dollar"
                    iconPosition="left"
                    className={serviceRow.labor_total_error ? "error-message" : ""}
                  />
                </Form.Field>
              </div>
              <div className="mmh-vehicle-info-fields">
                <Form.Field style={{ width: "100%" }}>
                  <label>{t("savingsLable")}</label>
                  <Input
                    onChange={(event) => {
                      if (roundUpTwoDecimals(event.target.value)) {
                        props.handleCurrentChange(SAVINGS, event.target.value, rowIndex)
                      }
                    }}
                    placeholder="0.0"
                    value={removeInitialZeros(serviceRow.savings)}
                    type="number"
                    min="0"
                    icon="dollar"
                    iconPosition="left"
                    className={serviceRow.savings_error ? "error-message" : ""}
                  />
                </Form.Field>
              </div>
            </div>
            <div className="mmh-vehicle-content">
              <div className="mmh-vehicle-info-fields">
                <Form.Field style={{ width: "100%" }}>
                  <label>{t("partsTotalLable")}</label>
                  <Input
                    onChange={(event) =>
                      props.handleCurrentChange("parts_total", event.target.value, rowIndex)
                    }
                    placeholder="0.0"
                    value={convertTwoDigitDecimal(serviceRow.parts_total)}
                    type="number"
                    icon="dollar"
                    iconPosition="left"
                    readOnly
                    className="disabled-field"
                  />
                </Form.Field>
              </div>
              <div className="mmh-vehicle-info-fields">
                <Form.Field style={{ width: "100%" }}>
                  <label>{t("subtotalLable")}</label>
                  <Input
                    onChange={(event) =>
                      props.handleCurrentChange("sub_total", event.target.value, rowIndex)
                    }
                    placeholder="0.0"
                    value={convertTwoDigitDecimal(serviceRow.sub_total)}
                    type="number"
                    icon="dollar"
                    iconPosition="left"
                    readOnly
                    className="disabled-field"
                  />
                </Form.Field>
              </div>
              <div className="mmh-vehicle-info-fields">
                <Form.Field style={{ width: "100%" }}>
                  <label>{t("totalpriceLable")}</label>
                  <Input
                    onChange={(event) =>
                      props.handleCurrentChange("total_price", event.target.value, rowIndex)
                    }
                    placeholder="0.0"
                    value={convertTwoDigitDecimal(serviceRow.total_price)}
                    type="number"
                    icon="dollar"
                    iconPosition="left"
                    readOnly
                    className="disabled-field"
                  />
                </Form.Field>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default withTranslation("maintenanceHistory")(HistoryAccordion)
