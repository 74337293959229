import { call, put, takeEvery } from "redux-saga/effects"
import api from "../../utilities/api"
import * as apiErrorHelpers from "../../helpers/apiErrorHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"
import { AUTHENTICATION_TOKEN } from "../../constants/application"
import { FAILURE, NOTIFICATION_TYPE } from "../../constants/notification"

const TRANSACTIONS_REPORT = "/fleet/reports/service_orders"
const CONFIGURED_REPORT_FILTERS = "/fleet/reports/order_filters"
const REPORT_SUMMARY_DATA = "/fleet/reports/transaction_totals"
const FILTERS = "/fleet/filters"

export function* loadTransactionsReport(action) {
  try {
    const payload = action.payload || {}

    yield put({ type: "SET_LOADING" })
    yield put({ type: "SET_TRANSACTION_REPORT_LOADER" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    yield put({ type: "TRANSACTIONS_REPORT_LOAD_REQUESTED" })

    const request = {
      url: TRANSACTIONS_REPORT,
      body: {
        page: payload.page,
        filters: payload.filters,
        is_fmc: true,
        filter_key: payload.filter_key
      }
    }

    const response = yield call(api.utility.get, api.pathV6(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "TRANSACTIONS_REPORT_LOAD_SUCCEEDED",
      payload: {
        transactionReportData: response.body
      }
    })

    if (action.callback) {
      action.callback()
    }
  } catch (errors) {
    errors = apiErrorHelpers.formatInternalErrors(errors)
    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadTransactionsReport

export function* loadConfigureReportFilters(action) {
  try {
    const payload = action.payload || {}

    yield put({ type: "SET_LOADING" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    yield put({ type: "CONFIGURED_REPORT_FILTERS_LOAD_REQUESTED" })

    const request = {
      url: CONFIGURED_REPORT_FILTERS,
      body: payload
    }

    const response = yield call(api.utility.get, api.pathV6(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "CONFIGURED_REPORT_FILTERS",
      payload: {
        configureFilters: response.body
      }
    })

    if (action.callback) {
      action.callback()
    }
  } catch (errors) {
    errors = apiErrorHelpers.formatInternalErrors(errors)
    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadConfigureReportFilters

export function* loadReportSummaryData(action) {
  try {
    const payload = action.payload || {}

    yield put({ type: "SET_LOADING" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    yield put({ type: "REPORT_SUMMARY_DATA_LOAD_REQUESTED" })

    const request = {
      url: REPORT_SUMMARY_DATA,
      body: { page: payload.page, filters: payload.filters, is_fmc: true }
    }

    const response = yield call(api.utility.get, api.pathV6(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "REPORT_SUMMARY_DATA",
      payload: {
        reportSummaryData: response.body
      }
    })

    if (action.callback) {
      action.callback()
    }
  } catch (errors) {
    errors = apiErrorHelpers.formatInternalErrors(errors)
    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadReportSummaryData

export function* loadFiltersData(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "SET_FILTER_REPORT_LOADER" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    yield put({ type: "FILTERS_LOAD_REQUESTED" })

    const request = {
      url: FILTERS,
      body: { is_fmc: true }
    }

    const response = yield call(api.utility.get, api.pathV6(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "FILTERS_LOAD_SUCCEEDED",
      payload: {
        filters: response.body.saved_filters
      }
    })

    if (action.callback) {
      action.callback()
    }
  } catch (errors) {
    errors = apiErrorHelpers.formatInternalErrors(errors)
    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadFilters

export function* createUpdateFilter(action) {
  try {
    const payload = action.payload || {}
    const { t } = payload
    const { reportEditEnabled } = payload
    yield put({ type: "SET_LOADING" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    yield put({ type: "CREATE_FILTER_REQUESTED" })

    const request = {
      url: reportEditEnabled ? `${FILTERS}/${payload.filter_key}` : FILTERS,
      body: { filter_name: payload.filter_name, filters: payload.filters },
      method: reportEditEnabled ? api.utility.patch : api.utility.post
    }

    const response = yield call(request.method, api.pathV6(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    if (response.status !== 200) {
      const afterRequestData = {
        title: reportEditEnabled ? t("updateLabel") : t("createLabel"),
        message: "Failed to save filter",
        type: NOTIFICATION_TYPE.ERROR
      }
      if (action.callback) {
        yield call(action.callback, NOTIFICATION_TYPE.ERROR, afterRequestData)
      }
      return
    }

    yield put({
      type: "CREATE_UPDATE_FILTER_SUCCEEDED",
      payload: {
        reportEditEnabled: reportEditEnabled,
        filter: response.body,
        filter_key: payload.filter_key
      }
    })

    const afterRequestData = {
      title: reportEditEnabled ? t("updateLabel") : t("createLabel"),
      message: reportEditEnabled
        ? t("reportSuccessfullyUpdatedLabel")
        : t("reportSuccessfullySavedLabel"),
      type: NOTIFICATION_TYPE.DEFAULT,
      filter: response.body
    }

    if (action.callback) {
      yield call(action.callback, NOTIFICATION_TYPE.SUCCESS, afterRequestData)
    }
  } catch (errors) {
    errors = apiErrorHelpers.formatInternalErrors(errors)

    const afterRequestData = {
      title: action.payload.t("errorLabel"),
      message: apiErrorHelpers.toString(errors),
      type: NOTIFICATION_TYPE.DANGER
    }

    if (action.callback) {
      yield call(action.callback, FAILURE, afterRequestData)
    }
    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    apiErrorHelpers.consoleLog(errors)
  }
} // function* createFilter

export function* deleteFilter(action) {
  try {
    const payload = action.payload || {}
    const { t } = payload
    yield put({ type: "SET_LOADING" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    yield put({ type: "DELETE_FILTER_REQUESTED" })

    const request = {
      url: `${FILTERS}/${payload.filter_key}`
    }

    const response = yield call(api.utility.del, api.pathV6(request.url))

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "DELETE_FILTER_SUCCEEDED",
      payload: {
        filter_key: payload.filter_key
      }
    })

    const afterRequestData = {
      title: t("deleteLabel"),
      message: t("reportSuccessfullyDeletedLabel"),
      type: NOTIFICATION_TYPE.DEFAULT
    }

    if (action.callback) {
      yield call(action.callback, NOTIFICATION_TYPE.SUCCESS, afterRequestData)
    }
  } catch (errors) {
    errors = apiErrorHelpers.formatInternalErrors(errors)

    const afterRequestData = {
      title: action.payload.t("errorLabel"),
      message: apiErrorHelpers.toString(errors),
      type: NOTIFICATION_TYPE.DANGER
    }

    if (action.callback) {
      yield call(action.callback, FAILURE, afterRequestData)
    }
    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    apiErrorHelpers.consoleLog(errors)
  }
} // function* deleteFilter

export function* downloadTransactionsReport(action) {
  try {
    const payload = action.payload || {}
    const authToken = sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)

    yield put({ type: "TRANSACTIONS_REPORT_DOWNLOAD_REQUESTED" })

    // Convert josn object to URL query parameters

    const params = new URLSearchParams()

    for (const key in payload.filters) {
      if (payload.filters[key] instanceof Array) {
        for (const value of payload.filters[key]) {
          params.append(`filters[${key}][]`, value)
        }
      } else if (payload.filters[key]) {
        params.append(`filters[${key}]`, payload.filters[key])
      }
    }
    let url = `${TRANSACTIONS_REPORT}.csv?${params.toString()}&auth_token=${authToken}&is_fmc=${true}`
    console.log(url)
    window.open(api.pathV6(url))
    return
  } catch (errors) {
    errors = apiErrorHelpers.formatInternalErrors(errors)
    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* downloadTransactionsReport

export default function* paymentActivitySaga() {
  yield takeEvery("LOAD_TRANSACTIONS_REPORT", loadTransactionsReport)
  yield takeEvery("LOAD_CONFIGURE_REPORT_FILTERS", loadConfigureReportFilters)
  yield takeEvery("LOAD_REPORT_SUMMARY_DATA", loadReportSummaryData)
  yield takeEvery("LOAD_FILTERS_DATA", loadFiltersData)
  yield takeEvery("CREATE_UPDATE_FILTER", createUpdateFilter)
  yield takeEvery("DELETE_FILTER", deleteFilter)
  yield takeEvery("DOWNLOAD_TRANSACTIONS_REPORT", downloadTransactionsReport)
}
