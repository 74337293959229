import { AgGridReact } from "ag-grid-react"
import PropTypes from "prop-types"
import React, { Component } from "react"
import { withTranslation } from "react-i18next"
import { Link, withRouter } from "react-router-dom"
import { Button, Image, Input, Segment } from "semantic-ui-react"
import HighlightIcon from "../../assets/images/HighlightIcon.png"
import { FIELD_AWAITING_APPROVAL_COUNT } from "../../constants/application"
import { agGridColumnDefs, agGridRowData } from "../../constants/fleets"
import { NOTIFICATION_TYPE } from "../../constants/notification"
import { navigationPaths } from "../../constants/paths"
import { isFleetAdvise } from "../../helpers/affiliationHelpers"
import * as agGridHelpers from "../../helpers/agGridHelpers"
import FMCTransactionsShimmer from "../../views/accounts/FMCTransactionsShimmer"
import CustomLoadingOverlay from "../shared/CustomLoadingOverlay"
import CustomNoRowsOverlay from "../shared/CustomNoRowsOverlay"
import CustomNotification from "../shared/CustomNotification"

class FleetList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      prevBottomDisplayedRowIndex: 0,
      search_text: ""
    }
    this.wrapperRef = React.createRef()
  }

  static propTypes = {
    fleets: PropTypes.array.isRequired
  }

  handleNavigateToServicePage = async (fleetId) => {
    const { dispatch } = this.props
    dispatch({
      type: "SERVICES_AWAITING_APPROVAL_FLAG",
      payload: { render_active_order_tab: true, fleetId }
    })
  }

  setFilteredItems = () =>
    this.setState({ filteredItems: this.api.getModel().rowsToDisplay.map((r) => r.data) })

  handleNavigate = () => {
    this.props.dispatch({
      type: "SERVICES_AWAITING_APPROVAL_FLAG",
      payload: { render_active_order_tab: false }
    })
  }

  defaultCellRenderer = (cellParams) => {
    const {
      data,
      colDef: { field }
    } = cellParams
    const fleetId = data.id
    const fleetAdvise = isFleetAdvise()

    return data.awaiting_approval_count > 0 && field === FIELD_AWAITING_APPROVAL_COUNT ? (
      <Link
        className={"ag-grid-link-column"}
        to={navigationPaths.servicesIndex()}
        onClick={() => this.handleNavigateToServicePage(fleetId)}
      >
        <div className={fleetAdvise ? "fleet-highlight" : "fleet-list-highlight"}>
          {fleetAdvise ? <Image src={HighlightIcon} /> : null}
          {cellParams.value}
        </div>
      </Link>
    ) : (
      <Link
        className={"ag-grid-link-column"}
        to={navigationPaths.fleetEdit(fleetId)}
        onClick={this.handleNavigate}
      >
        <div>{cellParams.value}</div>
      </Link>
    )
  }

  resetFiltering = () => this.api.setFilterModel([])

  onGridReady = (params) => {
    this.api = params.api
    this.columnApi = params.columnApi
  }

  handlePageChange = async ({ api: pageApi, newPage }) => {
    const { page, prevBottomDisplayedRowIndex } = this.state
    const { dispatch, handlePage } = this.props

    const bottomDisplayedRowIndex = pageApi.paginationProxy.bottomDisplayedRowIndex / 50
    if (newPage && bottomDisplayedRowIndex > prevBottomDisplayedRowIndex) {
      this.api.showLoadingOverlay()
      handlePage(page + 1)
      this.setState({ page: page + 1, prevBottomDisplayedRowIndex: bottomDisplayedRowIndex })
      if (this.state.search_text) {
        dispatch({
          type: "FLEETS_LOAD_SAGA",
          payload: {
            page: page + 1,
            query: this.state.search_text,
            filter: {
              filterType: "search_type",
              filterValue: this.state.selectedOption
            }
          },
          callback: this.afterLoadServices
        })
      } else {
        dispatch({
          type: "FLEETS_LOAD_SAGA",
          payload: {
            page: page + 1
          },
          callback: this.afterLoadServices
        })
      }
    }
  }

  afterLoadServices = (message) => {
    this.api.hideOverlay()
  }

  onTextChange = (event) => {
    const { dispatch } = this.props
    this.setState({ search_text: event.target.value })

    if (event.target.value == "") {
      this.api.showLoadingOverlay()
      dispatch({
        type: "FLEETS_LOAD_SAGA",
        payload: {
          page: 1,
          pageLoad: "false"
        },
        callback: this.afterLoadServices
      })
    }
  }

  onSearch = () => {
    const { t } = this.props
    if (this.state.search_text !== "" && this.state.search_text.length >= 3) {
      const { dispatch } = this.props
      this.api.showLoadingOverlay()
      dispatch({
        type: "FLEETS_LOAD_SAGA",
        payload: {
          page: 1,
          query: this.state.search_text,
          filter: {
            filterType: "seach_type",
            filterValue: this.state.selectedOption
          }
        },
        callback: this.afterLoadServices
      })
    } else {
      return CustomNotification(NOTIFICATION_TYPE.DANGER, "", t("invalidSearchValue"), t)
    }
  }

  render() {
    const { fleets, t, fleetsLoading } = this.props

    return (
      <Segment
        className={"ag-theme-material"}
        style={{
          height: agGridHelpers.dynamicGridHeight({ items: fleets })
        }}
      >
        <Input
          className="search"
          icon="search"
          onChange={this.onTextChange}
          placeholder="Search fleet..."
        />
        <Button
          disabled={!(this.state.search_text.length >= 1)}
          onClick={() => this.onSearch()}
          className={isFleetAdvise() ? "search-margin" : ""}
        >
          Search
        </Button>
        {fleetsLoading ? (
          <FMCTransactionsShimmer />
        ) : (
          <AgGridReact
            columnDefs={agGridColumnDefs(this.defaultCellRenderer, t)}
            onPaginationChanged={this.handlePageChange}
            defaultColDef={{
              sortable: true,
              filter: true,
              headerComponentParams: {
                template:
                  '<div class="ag-cell-label-container" role="presentation">' +
                  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                  '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                  "  </div>" +
                  "</div>"
              }
            }}
            gridOptions={{
              onFilterChanged: this.setFilteredItems
            }}
            onGridReady={this.onGridReady}
            rowData={agGridRowData(fleets)}
            rowSelection={"single"}
            frameworkComponents={{
              customNoRowsOverlay: CustomNoRowsOverlay,
              customLoadingOverlay: CustomLoadingOverlay
            }}
            noRowsOverlayComponent={"customNoRowsOverlay"}
            noRowsOverlayComponentParams={{
              translationFile: "fleets",
              label: "noFleetsLabel"
            }}
            cacheQuickFilter={true}
            pagination={true}
            paginationPageSize={agGridHelpers.PAGINATION_AMOUNT}
            suppressMenuHide={true}
            suppressMovableColumns={false}
            suppressCellSelection={true}
            loadingOverlayComponent={"customLoadingOverlay"}
            loadingOverlayComponentParams={{
              loadingMessage: t("oneMomentPlease")
            }}
          ></AgGridReact>
        )}
      </Segment>
    )
  }
} // class FleetList

export default withRouter(withTranslation("fleets")(FleetList))
